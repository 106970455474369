/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate HTTP proxy
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.7.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SsoProviderKindFromJSON, SsoProviderKindToJSON, } from './SsoProviderKind';
export function SsoProviderDescriptionFromJSON(json) {
    return SsoProviderDescriptionFromJSONTyped(json, false);
}
export function SsoProviderDescriptionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'label': json['label'],
        'kind': SsoProviderKindFromJSON(json['kind']),
    };
}
export function SsoProviderDescriptionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'label': value.label,
        'kind': SsoProviderKindToJSON(value.kind),
    };
}
